import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/utils/request';
export default {
  name: 'Index',
  data: function data() {
    return {
      fullscreenLoading: false,
      dialogVisible: false,
      templateName: null,
      selectClient: null,
      businessModule: null,
      tableData: [],
      clients: []
    };
  },
  created: function created() {
    console.log(this.$store);
    this.getTableData();
    this.getClients();
  },
  computed: {},
  methods: {
    canEdit: function canEdit(item) {
      try {
        var clientId = item.clientId;
        var state = this.$store.state;
        var user = state.user;
        return clientId == user.clientId;
      } catch (e) {
        return false;
      }
    },
    back: function back() {
      this.$router.back();
      console.log(this.tableData);
    },
    edit: function edit(item) {
      this.$router.push({
        path: '/sys/data-template/detail',
        query: {
          id: item.id
        }
      });
    },
    confirmTemplate: function confirmTemplate() {
      var _this = this;
      if (this.templateName === null) {
        this.$message.warning('请输入模块名称');
        return;
      }
      if (this.selectClient === null) {
        this.$message.warning('请选择客户名称');
        return;
      }
      if (this.businessModule === null) {
        this.$message.warning('请输入业务模块名称');
        return;
      }
      request({
        url: 'data-template/save',
        method: 'post',
        data: {
          clientId: this.selectClient,
          name: this.templateName,
          moduleName: this.businessModule,
          templateSheets: []
        }
      }).then(function (response) {
        if (response.status === 200) {
          _this.getTableData();
          _this.selectClient = null;
          _this.templateName = null;
          _this.businessModule = null;
          _this.dialogVisible = false;
        }
      });
    },
    deleteTemplate: function deleteTemplate(item) {
      var _this2 = this;
      this.$confirm('是否要删除模板:' + item.name, '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(function () {
        request({
          url: 'data-template/' + item.id,
          method: 'delete'
        }).then(function (response) {
          if (response.status === 200) {
            _this2.getTableData();
          }
        });
      }).catch(function () {});
    },
    downloadExcelTemplate: function downloadExcelTemplate(item) {
      var __this = this;
      this.fullscreenLoading = true;
      request({
        url: 'import-data/download-excel-template',
        method: 'post',
        headers: {
          'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
        data: {
          'client': item.clientId,
          'language': 1,
          'id': item.id,
          'moduleName': item.moduleName
        },
        attachmentFileName: item.name
      }).then(function (response) {
        setTimeout(function () {
          __this.fullscreenLoading = false;
        }, 1500);
      });
    },
    getTableData: function getTableData() {
      var _this3 = this;
      request({
        url: 'data-template/list',
        method: 'get',
        params: null
      }).then(function (response) {
        var __this = _this3;
        if (response.status === 200) {
          _this3.tableData = response.data;
          console.log(_this3.tableData);
        }
      });
    },
    getClients: function getClients() {
      var _this4 = this;
      request({
        url: 'data-template/clients',
        method: 'get'
      }).then(function (response) {
        if (response.status === 200) {
          _this4.clients = response.data;
          console.log(_this4.clients);
        }
      });
    },
    onSelectFile: function onSelectFile() {
      console.log('-----onSelectFile-----');
      console.log(arguments);
      console.log(this);
    },
    beforeUpload: function beforeUpload(file) {
      console.log(file);
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
    },
    getSelectValue: function getSelectValue(index, item) {
      for (var i = 0, length = this.excelTemplate.length; i < length; i++) {
        if (this.excelTemplate[i].fieldIndex == index) {
          return this.excelTemplate[i].fieldName;
        }
      }
      return '请选择...';
    },
    onChange: function onChange(module_name, item, index, value) {
      var _this5 = this;
      console.log(arguments);
      item[module_name] = value;
      request({
        url: 'data-template/save',
        method: 'post',
        data: item
      }).then(function (response) {
        if (response.status === 200) {
          _this5.getTableData();
        }
      });
    }
  }
};